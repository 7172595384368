.wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 12px;
  z-index: 1000;
  width: 50%;
  max-width: 700px;
}

.overlay {
  background: rgba(94, 94, 94, 0.7);
  backdrop-filter: blur(120px);
  height: 200px;
  color: white;
  font-family: var(--monospace);
  white-space: pre-wrap;
  font-weight: 700;
  font-size: 0.8rem;
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
  margin-top: var(--s);
}

.overlayLine {
  padding: 6px;
}

.overlayLine:nth-child(2n) {
  background: rgba(90, 90, 90, 0.7);
}

.overlayLine:hover {
  background: rgb(50, 50, 50);
}

.toggler {
  background: #888;
  appearance: none;
  cursor: pointer;
  padding: 12px;
  font-family: var(--monospace);
  font-size: 1rem;
  font-weight: bold;
  color: white;
}
