.tab {
  padding: var(--l);
  background: var(--ddd);
}

.activeTab {
  composes: tab;
  background: var(--eee);
}

.link {
  color: var(--ts-blue);
}

.notFound {
  font-size: 30rem;
  text-align: center;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--fff);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--000);
  z-index: 10000000000000000;
}

.github {
  padding-top: var(--m);
}
