.wrapper {
  display: flex;
  flex: 1;
  align-items: center;
  padding: 24px;
  gap: 24px;
  flex-direction: column;
}

.colorPicker {
  display: flex;
  flex-direction: column;
  background: var(--eee);
  padding: 24px;
  /* border-radius: 10px; */
  font-family: 'Silom';
  font-size: 2rem;
  align-self: flex-start;
  gap: 24px;
}

.container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(600px, 1fr));
  gap: 24px;
  width: 100%;
}

.cell {
  display: grid;
  grid-template-areas: 'ship details';
  grid-template-columns: auto auto;
  padding: 24px;
  /* border-radius: 10px; */
  gap: 24px;
  place-items: center;
  flex: 1;
}

.img {
  image-rendering: pixelated;
  height: 200px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: rotation 10s linear 0s infinite;
}

.infos {
  justify-self: start;
}

.id {
  text-transform: capitalize;
  font-size: 2rem;
}

.label {
  font-size: 1rem;
  color: var(--888);
}

.class {
  text-transform: capitalize;
}

.weapons {
  display: flex;
  gap: 12px;
}

.infoCard {
  display: flex;
  gap: 3px;
  flex-direction: column;
  background: var(--ccc);
  padding: 9px;
}

/* prettier-ignore */
@keyframes rotation {
  0% { transform: rotate(0deg); }
  50% { transform: rotate(180deg); }
  100% { transform: rotate(360deg); }
}

.summaryShipImage {
  width: 50px;
  image-rendering: pixelated;
}
