/* index.tsx */
.filesCardGrid {
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.fileName {
  border-bottom: 2px solid var(--ccc);
}

.logo {
  width: 20px;
  height: 20px;
}

.fileCard {
  border: 2px solid var(--ccc);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.addCard {
  composes: fileCard;
  border-style: dashed;
  background: var(--fff);
  font-weight: bold;
  font-size: 3rem;
  color: var(--ccc);
}

.modifs {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.modifsTitle {
  font-size: 0.7rem;
}

.tag {
  padding: 3px 6px;
  color: white;
  font-weight: 700;
  font-size: 1rem;
  /* border-radius: 6px; */
}

.cross {
  visibility: hidden;
  width: 0px;
  cursor: pointer;
}

.inputTagsName:hover .tag {
  gap: var(--s);
}
.inputTagsName:hover .tag .cross {
  visibility: visible;
  width: auto;
}

/* editor.tsx */
.monaco {
  flex: 1;
  display: flex;
}

.editor {
  flex: 3;
}

.sidePanel {
  flex: 2;
  display: flex;
}

.testRenderer {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.inputName {
  display: flex;
  align-items: center;
}
.inputTagsName {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: var(--s);
  border: 1px solid;
  border-color: var(--ddd0);
}
.inputTagsName:hover {
  border-color: var(--dddf);
}

/* prettier-ignore */
.input {
  font-family: inherit;
  padding: var(--s) 0px;
  padding-right: 0;
  margin: 0;
  font-size: 1.3rem;
  outline: none;
  background: var(--fff0);
  transition: all 0.2s;
  border: 1px solid;
  border-color: var(--ddd0);
}

.archivedInput {
  composes: input;
}

.unArchivedInput {
  composes: input;
}

.inputTags {
  composes: input;
  border: none;
  flex: 1;
  font-size: 1rem;
}

/* prettier-ignore */
.inputName:hover .unArchivedInput,
.unArchivedInput:focus {
  background: var(--ffff);
  padding: 6px 6px;
  border-color: var(--dddf);
}

.inputExtension {
  font-family: var(--monospace);
  font-size: 1.3rem;
  padding: 6px 0px;
}

.inputName:hover .inputExtension {
  padding: 6px 4px;
}

.spacer {
  padding: 0px 3px;
}

.updatedAt {
  background: var(--ccc);
  padding: 3px 6px;
}

.loader {
  width: 15px;
}
