.h1 {
  font-size: 2rem;
}

.h2 {
  font-size: 1.5rem;
}

.h3 {
  font-size: 1rem;
}

.p {
  font-size: 1rem;
  color: var(--888);
}

.blinking {
  animation: blinking 1s linear 0s infinite alternate;
}

/* prettier-ignore */
@keyframes blinking {
  from { opacity: 0; }
  to { opacity: 1; }
}

.button {
  line-height: inherit;
  padding: 12px;
  font-size: 1.5rem;
  background: var(--ddd);
}

.primaryButton {
  composes: button;
  background: var(--ts-blue);
  color: var(--white);
}

.secondaryButton {
  composes: button;
  background: var(--orange);
  color: var(--white);
}

.warningButton {
  composes: button;
  background: var(--warn-red);
  color: var(--white);
}

.button:disabled {
  background: var(--ddd);
  cursor: not-allowed;
}

.smallButton {
  padding: 3px 6px;
  font-size: 1rem;
}

.cookieConsent {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000000;
  padding: var(--xl);
}

.link {
  text-decoration: underline;
}
