.tile {
  height: 60px;
  width: 60px;
  /* border-radius: 16px; */
  border: solid 3px var(--grey);
  cursor: pointer;
}

.tile-blue {
  composes: tile;
  background: var(--team-blue);
}

.tile-green {
  composes: tile;
  background: var(--team-green);
}

.tile-red {
  composes: tile;
  background: var(--team-red);
}

.tile-yellow {
  composes: tile;
  background: var(--team-yellow);
}

.tile-white {
  composes: tile;
  background: var(--team-white);
}

.active {
  border-color: var(--space-yellow);
}
