.canvas {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.pause {
  position: fixed;
  bottom: 0;
  color: white;
  right: 0;
  margin: var(--m);
  padding: var(--m);
  z-index: 1000;
  font-family: var(--monospace);
}

.fullHeight {
  position: relative;
  flex: 1;
}

.renderShip {
  position: absolute;
  top: var(--m);
  right: var(--m);
  background: var(--eee);
  z-index: 10000;
}

.tab {
  padding: var(--m);
  background: var(--ccc);
}

.activeTab {
  composes: tab;
  background: var(--ddd);
}

.centerShip {
  background: var(--ddd);
}

.centerShip:hover {
  background: var(--ddd);
}
