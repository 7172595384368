.inputCheckbox {
  width: 1px;
  height: 1px;
  visibility: hidden;
  position: fixed;
  top: -10000vh;
  left: -10000vh;
}

.checkbox {
  width: 15px;
  height: 15px;
  border: 2px solid #000;
  cursor: pointer;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000;
}

.uncheckedBox {
  composes: checkbox;
}

.checkedBox {
  composes: checkbox;
}

.checkedMark {
  position: relative;
  top: -1px;
  left: 1px;
}
