.nav {
  position: sticky;
  top: 0;
  border-bottom: 2px solid var(--ddd);
  z-index: 10000;
}

.links {
  appearance: none;
  list-style-type: none;
}

.logo {
  font-size: 2rem;
}

.navLink {
  color: var(--888);
}

.navLink:global(.active) {
  color: var(--222);
}

.identicon {
  display: flex;
  align-self: center;
  align-items: center;
  background: var(--ddd);
}

.identiconImg {
  width: 30px;
  height: 30px;
}
