:root {
  --sans-serif: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial,
    sans-serif, Apple Color Emoji, Segoe UI Emoji;
  --monospace: Silom, ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas,
    Liberation Mono, monospace;
  --unifont: Unifont, ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas,
    Liberation Mono, monospace;

  --xs: 3px;
  --s: 6px;
  --m: 12px;
  --l: 18px;
  --xl: 24px;
  --xxl: 36px;

  --000: #fff;
  --111: #fff;
  --222: #eee;
  --333: #ddd;
  --444: #ccc;
  --555: #bbb;
  --666: #aaa;
  --777: #999;
  --888: #888;
  --999: #777;
  --aaa: #666;
  --bbb: #555;
  --ccc: #444;
  --ddd: #333;
  --eee: #222;
  --fff: #111;
  --fff0: #1110;
  --ffff: #111f;
  --ddd0: #3330;
  --dddf: #333f;
  --background-color: #000;
  --text-color: #fff;
  --space-yellow: #ffb000;
  --orange: orange;

  --team-blue: #11a5d4;
  --team-red: #dd442c;
  --team-yellow: #e2a106;
  --team-green: #92b115;
  --team-white: #959ab1;

  --black: black;
  --grey: #959ab1;
  --ts-blue: #4176c1;
  --white: white;
  --warn-red: #a80000;
  --google: rgb(216, 81, 64);
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--monospace);
  line-height: 1.1;
  height: 100vh;
  overscroll-behavior: none;
  background: var(--background-color);
  color: var(--text-color);
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

* {
  box-sizing: border-box;
  margin: 0;
}

.monaco-editor.rename-box {
  bottom: 0;
}

button {
  appearance: none;
  border: none;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  background: inherit;
  outline: none;
  cursor: pointer;
  color: inherit;
}

a {
  text-decoration: none;
  color: inherit;
  outline: none;
}

p {
  white-space: pre-line;
  font-family: var(--unifont);
}

input {
  color: inherit;
  border: none;
  font-family: var(--unifont);
  padding: var(--s);
  font-size: 1rem;
  outline: none;
  border-radius: 0px;
}

textarea {
  color: inherit;
}
