.ally {
  background: var(--ddd);
}

.ai {
  grid-area: ai;
  display: flex;
  flex-direction: column;
  gap: 3px;
  padding: 12px;
}

.pos {
  grid-area: pos;
  display: grid;
  grid-template-columns: repeat(2, 100px);
  grid-template-rows: repeat(5, 100px);
  gap: var(--m);
}

.cell {
  border: 2px solid var(--eee);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.2s;
  flex: 1;
}

.gridCell {
  display: flex;
  /* padding: 1rem; */
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
}

.gridCell img {
  cursor: pointer;
  transform: rotate(90deg);
}

.availableShip {
  padding: var(--m);
  background: var(--ddd);
  cursor: pointer;
  text-transform: capitalize;
  gap: var(--m);
  display: flex;
  flex-direction: column;
}

.img {
  display: block;
  image-rendering: pixelated;
}

.smallImg {
  composes: img;
  height: 32px;
  width: 32px;
}

.hugeImg {
  composes: img;
  height: 100px;
  width: 100px;
}

.ships {
  grid-area: ships;
  padding: 0 1rem;
  display: flex;
  flex-wrap: wrap;
}

.subtitle {
  margin-top: 1rem;
}

.shipSelector {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.ships img {
  image-rendering: pixelated;
  height: 120px;
  margin: 1rem;
}

.aiButton {
  display: flex;
}

.aiPath {
  background: var(--bbb);
  padding: 3px 6px;
  border-radius: 10px;
  cursor: pointer;
}

.teamName {
  padding: 3px 6px;
  color: white;
}

.tab {
  padding: var(--m);
  background: var(--eee);
  cursor: pointer;
}

.inactiveTab {
  composes: tab;
  background: var(--ddd);
}

.logo {
  width: 20px;
  height: 20px;
}

.pathName {
  font-size: 1.2rem;
}

.dates {
  color: var(--888);
  font-size: 0.7rem;
}

.cursor {
  cursor: pointer;
  flex: 1;
}

.alignSelfStart {
  align-self: flex-start;
}

.renderShips {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--m);
}

.numGuide {
  position: absolute;
  color: var(--ddd);
}

.visibleNumGuide {
  composes: numGuide;
  z-index: 100000;
  color: var(--888);
}

.lineGuide {
  position: absolute;
  border: 1px dashed var(--ddd);
}

.visibleLineGuide {
  composes: lineGuide;
  border: 1px dashed var(--888);
  z-index: 100000;
}

.inputNumber {
  background: var(--ddd);
  width: auto;
}

.inputNumber:focus {
  outline: 2px solid var(--fff);
}

.inputNumber:disabled {
  background: var(--fff);
  cursor: not-allowed;
  color: var(--ddd);
}

.detailsGrid {
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: var(--s);
  align-items: center;
  /* justify-items: end; */
}

.detailsGrid h3 {
  background: var(--fff);
  padding: var(--s);
  text-align: right;
}

.rotationPositions {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: var(--s);
}

.rotationShipImg {
  width: 25px;
}

.aiDescription {
  font-size: 0.9rem;
}

.creditsGauge {
  position: relative;
  height: 50px;
  width: 100%;
  border: 2px solid var(--space-yellow);
}

.gaugeInside {
  transition: all 1s;
  background: var(--space-yellow);
  height: 100%;
}

.gaugeInside2 {
  composes: gaugeInside;
  background: var(--warn-red);
}

.inscriptionGauge {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
}

.tabber {
  width: 300px;
}

@media (max-width: 1399px) {
  .positionAndAI {
    flex-direction: column;
  }
}

@media (min-width: 1400px) {
  .tabber {
    width: 400px;
  }
}
