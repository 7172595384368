.input {
  background: var(--fff);
  font-size: 1.1rem;
  padding: var(--m);
}

.label {
  font-size: 1.3rem;
}

.profilePictureDetails {
  width: 60px;
  height: 60px;
}

.turning {
  animation: turning 1s linear 0s infinite;
}

/* prettier-ignore */
@keyframes turning {
  0% { transform: rotate(0deg); }
  24% { transform: rotate(0deg); }
  25% { transform: rotate(90deg); }
  49% { transform: rotate(90deg); }
  50% { transform: rotate(180deg); }
  74% { transform: rotate(180deg); }
  75% { transform: rotate(270deg); }
  99% { transform: rotate(270deg); }
  100% { transform: rotate(360deg); }
}
